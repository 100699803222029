<template>
  <div class="mapping-view">
    <SportSidebar
      v-if="showSportsSidebar"
      title="Sports"
      :disabled="loading"
      :selected-sport-id="selectedSportId"
      @sport-updated="updateSport"
    />

    <div
      :class="[
        'mapping-view__main',
        { 'full-width': !showSportsSidebar },
        { 'sidebar-collapsed': sidebarCollapsed },
      ]"
    >
      <div class="mapping-view__header">
        <Heading>
          {{ title }}
        </Heading>
        <div
          v-if="initialized"
          class="mapping-header-filters-wrapper"
        >
          <MappingFeedFilter />
          <MappingSearchFilter />
          <MappingDisplayFilter />
          <MappingDateFilter
            v-if="title === 'Events mapping'"
          />
          <MappingFeedsOrder v-if="showDisplayFeedsFilter" />
        </div>
      </div>

      <div
        v-if="initialized"
        ref="scrollerElement"
        class="mapping-view__page"
        @scroll="handleScroll"
      >
        <Spinner
          v-if="loading"
        />
        <router-view
          v-else-if="selectedSportId || !showSportsSidebar"
          :top-header-position="topHeaderPosition"
        />
        <div
          v-else
        />
      </div>
      <div
        v-else
        class="mapping-view__page"
      >
        <Spinner />
      </div>

      <Footer
        :class="{
          'full-width': !showSportsSidebar,
          'sidebar-collapsed': sidebarCollapsed,
        }"
      />
    </div>
  </div>
</template>

<script>
import {
  debounce,
  includes,
  isEqual,
  find,
  pickBy,
  identity,
} from 'lodash';
import {
  ref,
  computed,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { parseMappingTypeFromRouteName, feedTypes } from '@/services/helpers/mappings-mapper';
import Heading from '@/components/common/Heading';
import Spinner from '@/components/common/Spinner';
import SportSidebar from '@/components/common/SportSidebar';
import Footer from '@/components/common/Footer';
import MappingSearchFilter from './MappingSearchFilter';
import MappingFeedFilter from './MappingFeedFilter';
import MappingDisplayFilter from './MappingDisplayFilter';
import MappingDateFilter from './MappingDateFilter';
import MappingFeedsOrder from './MappingFeedsOrder';

export default {
  components: {
    Heading,
    Spinner,
    SportSidebar,
    Footer,
    MappingSearchFilter,
    MappingFeedFilter,
    MappingDisplayFilter,
    MappingDateFilter,
    MappingFeedsOrder,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const scrollerElement = ref(null);
    const initialized = computed(() => store.getters.mappingInitialized);
    // const currentPage = computed(() => route.query?.page ?? 1);

    const topHeaderPosition = ref(0);

    const filterFeedsBySport = computed(() => router.currentRoute.value.name !== 'regionsMapping');
    const showDisplayFeedsFilter = computed(() => filterFeedsBySport.value && store.getters.mappingSelectedFeed === feedTypes.HUDDLE);
    const mappingType = computed(() => parseMappingTypeFromRouteName(route.name));
    const showSportsSidebar = computed(() => {
      switch (route.name) {
      case 'eventsMapping': return true;
      case 'competitorsMapping': return true;
      case 'regionsMapping': return false;
      case 'competitionsMapping': return true;
      case 'playersMapping': return true;
      default: return false;
      }
    });
    const sidebarCollapsed = computed(() => !store.getters.sidebarExpanded);
    const title = computed(() => {
      switch (route.name) {
      case 'eventsMapping':
        return 'Events mapping';
      case 'competitorsMapping':
        return 'Competitors mapping';
      case 'regionsMapping':
        return 'Regions mapping';
      case 'competitionsMapping':
        return 'Competitions mapping';
      case 'playersMapping':
        return 'Players mapping';
      default:
        return 'Mapping';
      }
    });
    const loading = computed(() => store.getters.mappingLoading);
    const selectedSportId = computed(() => store.getters.mappingSelectedSportId);
    const selectedSportLabel = computed(() => store.getters.mappingSelectedSportLabel);

    const updateSport = (sport) => {
      if (!sport || !sport.sportId || sport.sportId === selectedSportId.value) return;
      router.replace({
        ...route,
        query: pickBy({ sport: sport?.sportId }, identity),
      });
    };

    const primaryFeeds = computed(() => store.getters.mappingPrimaryFeeds);
    const secondaryFeeds = computed(() => store.getters.mappingSecondaryFeeds);
    const selectedPrimaryFeed = computed(() => store.getters.mappingSelectedFeed);

    const selectedTimeOption = computed(() => store.getters.mappingSelectedDate);
    const selectedMappingStatus = computed(() => store.getters.mappingDisplayState);

    const handleScroll = (e) => {
      topHeaderPosition.value = e.target.scrollTop;
    };

    watch(
      () => store.getters.currentPage,
      () => {
        store.dispatch('clearMappingTableEntries');
        scrollerElement.value.scrollTo(0, 0);
        topHeaderPosition.value = 0;
      },
    );

    watch(
      () => route.name,
      (newValue) => {
        if (!includes(newValue, 'Mapping')) return;
        store.dispatch('selectMappingFeed', 'huddle');
        store.dispatch('setMappingDisplayState', ['mapped', 'not-mapped']);
        store.dispatch('selectMappingDate', 7);
        store.dispatch('setMappingSearchOption', 'name');
        store.dispatch('setMappingSearchQuery', '');
        store.dispatch('loadMappingData', parseMappingTypeFromRouteName(newValue));
        topHeaderPosition.value = 0;
      },
    );

    watch(
      () => route.query.sport,
      () => {
        const sport = find(store.getters.mappingSports, { sportId: route.query.sport }) ?? store.getters.mappingSports[0];
        if (filterFeedsBySport.value) {
          store.dispatch('loadFeedsPerSport', sport?.sportId);
        }
        store.dispatch('setMappingSearchQuery', '');
        store.dispatch('selectMappingSport', sport);
        store.dispatch('loadMappingData', mappingType.value);
        topHeaderPosition.value = 0;
      },
    );

    watch(
      () => [
        route.name,
        store.getters.mappingSelectedSportId,
        store.getters.mappingSelectedFeed,
        store.getters.mappingSelectedDisplay,
        store.getters.mappingSelectedDate,
        store.getters.mappingSelectedSearchOption,
        store.getters.mappingSearchQuery,
        store.getters.rowsPerPage,
        store.getters.currentPage,
      ],
      debounce((newValues, oldValues) => {
        if (isEqual(newValues, oldValues)) return;

        if (
          newValues[1] !== oldValues[1] // selected sport changed
          || newValues[2] !== oldValues[2] // selected feed changed
          || newValues[3] !== oldValues[3] // selected display changed
          || newValues[4] !== oldValues[4] // selected date changed
          || newValues[5] !== oldValues[5] // search option changed
          || newValues[6] !== oldValues[6] // search query changed
          || newValues[7] !== oldValues[7] // rows per page changed
        ) {
          store.dispatch('setPaginationPage', 1);
        }

        if (
          newValues[0] !== oldValues[0] // current route changed
          || newValues[1] !== oldValues[1] // selected feed changed
        ) {
          store.dispatch('setMappingSearchQuery', '');
        }

        store.dispatch('loadMappingData', mappingType.value);
        topHeaderPosition.value = 0;
      }, 1),
    );

    onMounted(() => {
      store.dispatch('initializeMapping')
        .then(() => {
          const sport = find(store.getters.mappingSports, { sportId: route.query.sport });

          if (!sport) {
            router.replace({
              ...route,
              query: pickBy({ sport: store.getters.mappingSports[0]?.sportId }, identity),
            });
            return;
          }
          store.dispatch('loadFeedsPerSport', sport?.sportId);
          store.dispatch('selectMappingSport', sport);
          store.dispatch('loadMappingData', mappingType.value);
        });
    });

    onUnmounted(() => {
      store.dispatch('resetMappingData');
    });

    return {
      scrollerElement,
      initialized,
      title,
      loading,
      sidebarCollapsed,
      primaryFeeds,
      secondaryFeeds,
      selectedPrimaryFeed,
      showSportsSidebar,
      selectedTimeOption,
      selectedMappingStatus,
      selectedSportId,
      selectedSportLabel,
      updateSport,
      handleScroll,
      topHeaderPosition,
      filterFeedsBySport,
      showDisplayFeedsFilter,
    };
  },
};
</script>

<style lang="scss">
.mapping-view {
  width: 100%;

  .mapping-view__main {
    flex: 1;
    width: calc(100% - #{$sidebarWidth});
    height: 100%;

    &.full-width {
      width: 100%;
    }

    &.sidebar-collapsed {
      width: calc(100% - #{$sidebarWidthCollapsed});
    }

    .footer {
      position: fixed;
      bottom: 0;
      width: calc(100% - #{$sidebarWidth});
      z-index: $mappingTableFooter;

      &.full-width {
        width: 100%;
      }

      &.sidebar-collapsed {
        width: calc(100% - #{$sidebarWidthCollapsed});
      }
    }
  }

  .mapping-view__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 95px;
    padding: 24px;

    .mapping-view__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mapping-header-filters-wrapper {
      display: flex;
      height: 100%;
      align-items: center;

      .header-filter-wrapper {
        height: 33px;
        position: relative;
        display: flex;
        align-items: center;

        &:last-child {
          margin-left: 12px;
        }

        .selected-filter {
          border: 1px solid #DDDDDD;
          box-sizing: border-box;
          border-radius: 4px;
          padding: 0 12px;
          display: flex;
          align-items: center;
          min-width: 165px;
          height: 100%;
          justify-content: center;
          cursor: pointer;

          .icon {
            margin-left: 4px;

            svg {
              stroke: #191414;
            }
          }

          img {
            height: 14px;
            width: 14px;
            margin-right: 4px;
          }
        }

        .filter-dropdown {
          position: absolute;
          top: 40px;
          left: 0;
          width: 100%;
          background-color: #fff;
          border: 1px solid #EBEBEB;
          box-sizing: border-box;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
          border-radius: 6px;
          z-index: $dropdownMenuZIndex;
          padding: 12px;

          .dropdown-option {
            height: 33px;
            display: flex;
            align-items: center;
            padding: 0 12px;
            box-sizing: border-box;
            cursor: pointer;
            color: #6D6D6D;

            .option-checkbox {
              width: 16px;
              height: 16px;
              border: 2px solid #CDCDCD;
              box-sizing: border-box;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 10px;

              &.checked {
                background-color: #003C3C;
                border: 2px solid #003C3C;
              }

              .icon {
                width: 12px;
                height: 12px;

                svg {
                  stroke: #fff;
                }
              }
            }

            img {
              height: 14px;
              width: 14px;
              margin-right: 4px;
            }

            &.time, &.feed {
              &.selected {
                background-color: rgba(0, 0, 0, 0.02);
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.03);
              }

              .icon {
                margin-right: 4px;
              }
            }

            &.feed {
              padding: 0;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .mapping-view__page {
    width: 100%;
    height: calc(100% - 100px - #{$footerPaginationHeight});
    overflow: auto;
  }
}

</style>
